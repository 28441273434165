import type { EntitiesTypeMap, Entity, EntityType, MapState } from "./types";
import { mapState } from "./map";
import type { Geometry } from "ol/geom";
import type VectorSource from "ol/source/Vector";
import { get } from "lodash";

type ContextState = WithRequired<MapState, "map" | "entitiesLayer">;
type Context = ContextState & {
  entitiesLayerSource: VectorSource<Geometry>;
  // addEntityToStore: { (entity: Entity): void }
};

type Func<A extends any[], R> = { (...args: A): R };

export function provideMapState<Arg extends any[], R>(fn: {
  (this: Context, ...args: Arg): R;
}): Func<Arg, R> {
  return function (...args) {
    if (!mapState.map || !mapState.entitiesLayer) {
      throw new Error();
    }

    const context: Context = {
      ...(mapState as ContextState),
      // addEntityToStore: (entity) => (state.entities[entity.id] = entity),
      get entitiesLayerSource(): VectorSource<Geometry> {
        return mapState.entitiesLayer!.getSource()!;
      },
    };

    return fn.apply(context, args);
  };
}

export function switchEntity<
  R,
  K extends EntityType,
  D extends EntitiesTypeMap[K]
>(entity: Entity, sw: Record<K, { (d: D): R }>) {
  return (): R | void => {
    const fn = get(sw, entity.type);
    if (fn) {
      return fn(entity as D);
    }
  };
}

import type { Geometry } from "ol/geom";
import type { Extent } from "ol/extent.js";
import { isPoint } from "../features";
import type { InteractionType } from "../types";

/**
 * Get the top right coordinates of an extent.
 * @param extent
 * @returns
 */
export const getRopRight = (extent: Extent): [number, number] => {
  return [extent[2], extent[3]];
};

/**
 * Determines if the given geometry crosses the Antimeridian.
 *
 * @remarks
 * This implementation depends on the expectation that all geometries are
 * normalised to have their minimum longitude value in the range -180 to 180,
 * while their maximum longitude value may be greater than 180, iff the geometry
 * crosses the antimeridian.
 *
 * @param geom
 * @returns
 */
export const isAntimeridianCrossed = (geom: Geometry): boolean => {
  return getRopRight(geom.getExtent())[0] > 180;
};

export const isOnlyOneCrossingAntimeridian = (
  a: Geometry,
  b: Geometry
): boolean => {
  const aCrosses = isAntimeridianCrossed(a);
  const bCrosses = isAntimeridianCrossed(b);

  // XOR
  return (aCrosses || bCrosses) && !(aCrosses && bCrosses);
};

export const isIntersectingSimple = (a: Geometry, b: Geometry): boolean => {
  if (isPoint(b)) {
    return a.intersectsCoordinate(b.getCoordinates());
  } else {
    return a.intersectsExtent(b.getExtent());
  }
};

export const createTranslatedCloneOf = (
  target: Geometry,
  controller: Geometry
): Geometry => {
  const clone = target.clone();
  if (isAntimeridianCrossed(controller)) {
    clone.translate(360, 0);
  } else {
    clone.translate(-360, 0);
  }
  return clone;
};

export const isIntersecting = (a: Geometry, b: Geometry): boolean => {
  let intersecting: boolean = isIntersectingSimple(a, b);
  if (!intersecting && isOnlyOneCrossingAntimeridian(a, b)) {
    const bCopy = createTranslatedCloneOf(b, a);
    intersecting = isIntersectingSimple(a, bCopy);
  }
  return intersecting;
};

export const isShapeInteraction = (type: InteractionType) =>
  type.startsWith("shape:");
export const isTableInteraction = (type: InteractionType) =>
  type.startsWith("table:");

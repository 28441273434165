import { v4 as uuidv4 } from "uuid";
import { EntityType, ShapeStyle, TableStyle } from "../types";
import type { Entity, RoundTable } from "../types";
import { isShape, isTable } from "./checks";
import { pick } from "lodash";

export const getRouteTable = (entity: RoundTable) => {
  const size = 300;
  const radius = entity.seats > 10 ? 50 : 35;
  const chairRadius = entity.seats > 10 ? 75 : 60;
  const center = size / 2;

  const chairs: {
    x: number;
    y: number;
    r: number;
  }[] = [];

  for (let i = 0; i < entity.seats; i++) {
    const angle = (Math.PI * 2 * i) / entity.seats;
    const x = chairRadius * Math.sin(angle) + center;
    const y = chairRadius * Math.cos(angle) + center;
    chairs.push({ x, y, r: 12 });
  }

  return {
    size,
    radius,
    chairRadius,
    center,
    chairs,
  };
};

export const entityWithDefaults = <T extends Entity>(input: Partial<T>): T => {
  input = {
    name: "",
    id: uuidv4(),
    ...input,
  };

  const defaults = {
    table: {
      type: EntityType.TABLE,
      style: TableStyle.ROUND,
      seats: 10,
      reserved: false,
      endSeats: 2, // rectangular table
    },
    shape: {
      type: EntityType.SHAPE,
      style: ShapeStyle.CIRCLE,
      icon: undefined,
      radius: undefined, // for circles
    },
  };

  if (isTable(input as any)) {
    return compactEntity<any>({
      ...defaults.table,
      ...input,
    });
  }

  if (isShape(input as any)) {
    return compactEntity<any>({
      ...defaults.shape,
      ...input,
    });
  }

  return compactEntity<any>(input);
};

export const compactEntity = <T extends Entity>(input: T): T => {
  const keys = ["id", "name", "type"];
  const tableKeys = [...keys, "style", "seats", "reserved", "endSeats"];
  const shapeKeys = [...keys, "style", "icon", "radius"];

  if (isTable(input as any)) {
    return pick(input, tableKeys) as T;
  }

  if (isShape(input as any)) {
    return pick(input, shapeKeys) as T;
  }

  return input;
};

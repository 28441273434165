import colors from "vuetify/lib/util/colors.mjs";
import type { FeatureLike } from "ol/Feature";
import type { Entity, TableEntity } from "../types";
import { Fill, Icon, RegularShape, Stroke, Style, Text } from "ol/style";
import type { StyleFunction } from "ol/style/Style";
import {
  isArrowShape,
  isRoundTable,
  isSection,
  isTable,
  isText,
} from "./checks";
import {
  generateRectangleTableSvg,
  generateRoundTableSvg,
  gridSvg,
} from "./svg";
import { LineString, Point } from "ol/geom";
import { last } from "lodash";
import type { Coordinate } from "ol/coordinate";

export function createStyleFunction(selected = false): StyleFunction {
  const outlineColor = selected ? colors.blue.base : colors.indigo.lighten1;

  const fill = new Fill({ color: "#FFFFFF" });
  const stroke = new Stroke({
    color: outlineColor, //
    width: 2,
  });

  return function (feature: FeatureLike, resolution: number): Style | Style[] {
    const entity: Entity | undefined = feature.get("entity");

    if (!entity) {
      return new Style({
        fill,
        stroke,
      });
    }

    const text = new Text({
      text: entity.name,
      font: "italic 12px sans-serif",
      textAlign: "center",
      fill,
      stroke,
    });

    if (isSection(entity)) {
      return new Style({
        image: new Icon({
          src: "data:image/svg+xml;base64," + gridSvg(),
          // color: outlineColor,
        }),
        text,
      });
    }

    if (isTable(entity)) {
      const icon = new Icon({
        src: "data:image/svg+xml;base64," + generateTableSvg(selected, entity),
        // color: outlineColor,
      });
      icon.setScale((1 / resolution) * 0.25);
      return new Style({
        image: icon,
        text,
      });
    }

    if (isArrowShape(entity)) {
      const styles = [new Style({ stroke })];

      const geometry = feature.getGeometry()! as LineString;
      const segments: [Coordinate, Coordinate][] = [];

      geometry.forEachSegment(function (start, end) {
        segments.push([start, end]);
      });

      // arrow
      const [start, end] = last(segments) as [Coordinate, Coordinate];
      const dx = end[0] - start[0];
      const dy = end[1] - start[1];
      const rotation = Math.atan2(dy, dx);
      styles.push(
        new Style({
          geometry: new Point(end),
          // image: new Icon({
          //   src: 'data/arrow.png',
          //   anchor: [0.75, 0.5],
          //   rotateWithView: true,
          //   rotation: -rotation,
          // }),
          image: new RegularShape({
            fill: new Fill({ color: outlineColor }),
            points: 3,
            radius: 8,
            rotation: -rotation,
            angle: Math.PI / 2, // rotate 90°
          }),
        })
      );

      return styles;
    }

    if (isText(entity)) {
      //
    }

    return new Style({
      fill,
      stroke,
      text,
    });
  };
}

function generateTableSvg(selected: boolean, entity: TableEntity) {
  return isRoundTable(entity)
    ? generateRoundTableSvg(selected, entity)
    : generateRectangleTableSvg(selected, entity);
}

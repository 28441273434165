import type Feature from "ol/Feature";
import { platformModifierKeyOnly } from "ol/events/condition";
import { DragBox, Select as OlSelect } from "ol/interaction.js";
import type { Interaction } from "ol/interaction.js";
import type Map from "ol/Map";
import { createStyleFunction } from "../entity";
import VectorSource from "ol/source/Vector";
import { isIntersecting } from "./utils";
import Collection from "ol/Collection";
import { SelectEvent } from "ol/interaction/Select";
import { noFeaturePointed } from "./condition";
import { get } from "lodash";

export class Select extends OlSelect {
  private dragInteraction?: Interaction;
  private get map(): Map | null {
    return this.getMap();
  }

  constructor() {
    super({
      hitTolerance: 9,
      multi: true,
      // Use ctrl to toggle selection
      toggleCondition: platformModifierKeyOnly,
      style: createStyleFunction(true),
      filter(feature) {
        return !!feature.get("entity");
      },
    });
  }

  public setActive(active: boolean): void {
    if (active) {
      const interaction = this.createMultiSelectInteraction();
      this.map?.addInteraction(interaction);
    } else if (this.dragInteraction) {
      this.map?.removeInteraction(this.dragInteraction);
    }
    super.setActive(active);
  }

  private createMultiSelectInteraction(): DragBox {
    const dragBox = new DragBox({
      condition: noFeaturePointed,
    });

    this.dragInteraction = dragBox;

    // dragBox.on('boxstart', () => {
    //   this.getFeatures().clear()
    // })

    dragBox.on("boxend", (event) => {
      if (!this.map) {
        return;
      }
      const features = this.getFeatures();
      const deselected = new Collection<Feature>();
      const boxExtent = dragBox.getGeometry().getExtent();

      const includes = (
        features: Collection<Feature> | Feature[],
        feature: Feature
      ): boolean => {
        const arr: Feature[] = Array.isArray(features)
          ? features
          : features.getArray();

        const id = feature.getId() || get(feature, "ol_uid");
        const ids = arr.map((feat) => feat.getId() || get(feat, "ol_uid"));

        return ids.includes(id) || arr.includes(feature);
      };

      for (const layer of this.map.getAllLayers()) {
        const layerSource = layer.getSource();
        if (layerSource instanceof VectorSource) {
          const boxSelectedFeatures = layerSource
            .getFeaturesInExtent(boxExtent)
            .filter(
              (feature) =>
                !!feature.get("entity") &&
                isIntersecting(feature.getGeometry(), dragBox.getGeometry())
            );

          const boxDeselectedFeatures: Feature[] = [];

          for (const feature of layerSource.getFeatures()) {
            if (
              !!feature.get("entity") &&
              includes(features, feature) &&
              !includes(deselected, feature)
            ) {
              boxDeselectedFeatures.push(feature);
              features.remove(feature);
            }
          }

          features.extend(boxSelectedFeatures);
          deselected.extend(boxDeselectedFeatures);
        }
      }

      this.dispatchEvent(
        new SelectEvent(
          "select",
          features.getArray(),
          deselected.getArray(),
          event as any
        )
      );
    });

    return dragBox;
  }
}

import type { Circle, Geometry, LineString, Point } from "ol/geom";

export const isPolygon = (geom?: Geometry): geom is Circle =>
  geom?.getType() === "Polygon";

export const isCircle = (geom?: Geometry): geom is Circle =>
  geom?.getType() === "Circle";

export const isPoint = (geom?: Geometry): geom is Point =>
  geom?.getType() === "Point";

export const isLineString = (geom?: Geometry): geom is LineString =>
  geom?.getType() === "LineString";

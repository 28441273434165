import Feature from "ol/Feature";
import { type Entity, ShapeStyle } from "../types";
import { isCircleShape, isRoundTable, isShape } from "./checks";
import { Circle, LineString, Point, Polygon, SimpleGeometry } from "ol/geom";
import { provideMapState } from "../helpers";
import { get, set } from "lodash";
import { getRouteTable } from "./utils";

export const getEntityGeometry = (entity: Entity): SimpleGeometry => {
  if (isShape(entity)) {
    const geom = match(entity.style, {
      [ShapeStyle.CIRCLE]: () => new Circle(entity.coordinates),
      [ShapeStyle.LINE_STRING]: () => new LineString(entity.coordinates),
      [ShapeStyle.ARROW]: () => new LineString(entity.coordinates),
      default: () => new Polygon(entity.coordinates),
    });
    if (isCircleShape(entity)) {
      (<Circle>geom).setRadius(entity.radius || 0);
    }
    return geom;
  } else {
    const geom = new Point(entity.coordinates);
    // Object.assign(geom, {
    //   extent_: [
    //     19094.371942821854, 23656.71647058824, 19094.371942821854,
    //     23656.71647058824,
    //   ],
    //   stride: 2,
    //   flatCoordinates: [19094.371942821854, 23656.71647058824],
    // })
    return geom;
  }
};

export const makeFeatureFromEntity = provideMapState(function (
  entity: Entity,
  feature?: Feature
) {
  if (!feature) {
    const geom = getEntityGeometry(entity);
    feature = new Feature(geom);
    set(feature, "ol_uid", entity.id);
  }

  feature.set("entity", entity);

  return feature;
});

/**
 * For picking seat (readonly) map.
 *
 * @param entity
 * @returns
 */
export const makeFeaturesFromEntity = (entity: Entity): Feature[] => {
  const features: Feature[] = [];

  if (isRoundTable(entity)) {
    const data = getRouteTable(entity);
    const circle = new Circle(entity.coordinates, data.radius * 0.25);
    const feature = new Feature(circle);
    set(feature, "ol_uid", entity.id);
    feature.set("entity", entity);
    features.push(feature);

    // this format must follow backend formatting
    // TODO: perhaps get this value from backend
    const seatsFormatted: string[] = [];
    for (let j = 0; j < entity.seats; j++) {
      seatsFormatted.push(`T:${entity.name}|S:${j + 1}`);
    }

    let i = 0;
    for (const chair of data.chairs) {
      const seatId = get(seatsFormatted, i++);
      const radius = data.chairRadius * 0.25;
      const angle = (Math.PI * 2 * i) / entity.seats;
      const x = radius * Math.sin(angle);
      const y = radius * Math.cos(angle);

      const geom = new Circle(entity.coordinates, chair.r * 0.25);
      geom.translate(x, y);
      const feat = new Feature(geom);

      feat.set("seat", i);
      feat.set("seatId", seatId);
      feat.set("entity", entity);

      features.push(feat);
    }

    //
  } else {
    const geom = getEntityGeometry(entity);
    const feature = new Feature(geom);
    set(feature, "ol_uid", entity.id);
    feature.set("entity", entity);
    features.push(feature);
  }

  return features;
};

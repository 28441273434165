import Map from "ol/Map";
import View from "ol/View";
import VectorLayer from "ol/layer/Vector.js";
import VectorSource from "ol/source/Vector.js";
import {
  DragPan,
  Translate,
  defaults as defaultInteractions,
} from "ol/interaction.js";
import { Stroke } from "ol/style.js";
import Graticule from "ol/layer/Graticule.js";
import { createStyleFunction } from "./entity/style-function";
import { Projection } from "ol/proj";
import ZoomSlider from "ol/control/ZoomSlider.js";
import { events } from "./events";
import { makeFeatureFromEntity } from "./entity";
import Kinetic from "ol/Kinetic";
import { InteractionType, type MapState } from "./types";
import { Select } from "./interactions/Select";
import { type MapSaveState, useSeatingStore } from "./store";
import { getCenter } from "ol/extent";

export const mapState: MapState = {
  interaction: undefined,
  map: undefined,
};

export const createMap = (target: string | HTMLElement) => {
  const store = useSeatingStore();

  const graticule = new Graticule({
    strokeStyle: new Stroke({
      color: "rgba(0,0,0,0.3)",
      width: 1,
      lineDash: [0.5, 4],
    }),
    wrapX: false,
  });

  const layerSource = new VectorSource({ wrapX: false });

  const select = new Select();
  const slider = new ZoomSlider();
  const translate = new Translate();
  const dragPan = new DragPan({
    kinetic: new Kinetic(-0.005, 0.05, 100),
  });

  const interactions = defaultInteractions({
    dragPan: false,
    doubleClickZoom: false,
  });

  const width = 1920;
  const height = 1080;
  const extent = [0, -height, width, 0];

  const viewPortDefaults = {
    zoom: 18,
    center: getCenter(extent),
  };

  const projection = new Projection({
    code: "ZOOMIFY",
    units: "pixels",
    extent: [0, 0, width, height],
  });

  const entitiesLayer = new VectorLayer({
    source: layerSource,
    style: createStyleFunction(),
    extent,
  });

  const map = new Map({
    layers: [entitiesLayer, graticule],
    controls: [slider],
    interactions: interactions.extend([select, translate, dragPan]),
    view: new View({
      extent,
      maxZoom: 20,
    }),
  });

  map.getView().fit(extent);
  map.getView().setZoom(viewPortDefaults.zoom);
  map.getView().setCenter(viewPortDefaults.center);

  mapState.map = map;
  mapState.entitiesLayer = entitiesLayer;

  const loadMap = ({ entities, viewport }: Required<MapSaveState>) => {
    const view = map.getView();
    view.setCenter(viewport.center);
    view.setZoom(viewport.zoom);
    for (const entity of entities) {
      const feature = makeFeatureFromEntity(entity);
      layerSource.addFeature(feature);
    }
  };

  events.hook("loaded", loadMap);

  events.hook("added", (entity) => {
    if (!layerSource.getFeatureByUid(entity.id)) {
      const feature = makeFeatureFromEntity(entity);
      layerSource.addFeature(feature);
    }
  });

  events.hook("updated", (entity) => {
    const feature = layerSource.getFeatureByUid(entity.id);
    if (feature) {
      feature.set("entity", entity);
    }
  });

  events.hook("removed", (entities) => {
    for (const entity of entities) {
      const feature = layerSource.getFeatureByUid(entity.id);
      if (feature) {
        layerSource.removeFeature(feature);
      }
    }
  });

  events.hook("map-cleared", () => {
    layerSource.clear();
  });

  events.hook("map-reload", () => {
    layerSource.clear();
    loadMap(store.getSeatingMap());
  });

  const interactionsMap = [
    {
      type: InteractionType.SELECT,
      interactions: [select, translate],
    },
    { type: InteractionType.PAN, interactions: [dragPan] },
  ];

  const switchInteraction = (interaction: InteractionType) => {
    for (const { type, interactions } of interactionsMap) {
      if (type === interaction) {
        interactions.forEach((entry) => entry.setActive(true));
      } else {
        interactions.forEach((entry) => entry.setActive(false));
      }
    }
    store.interaction = interaction;
  };

  const initMap = () => map.setTarget(target);

  events.hook("switch-interaction", switchInteraction);

  return {
    map,
    select,
    translate,
    dragPan,
    entitiesLayer,
    layerSource,
    switchInteraction,
    initMap,
  };
};

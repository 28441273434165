import colors from "vuetify/lib/util/colors.mjs";
import type { RectangleTable, RoundTable } from "../types";
import { getRouteTable } from "./utils";

export function generateRoundTableSvg(selected: boolean, entity: RoundTable) {
  const outLineColor = selected ? colors.blue.base : colors.indigo.lighten1;
  const fill = entity.reserved ? "black" : "white";

  const data = getRouteTable(entity);

  const chairs: string[] = [];

  let i = 0;
  for (const chair of data.chairs) {
    chairs.push(
      `<circle cx="${chair.x}" cy="${chair.y}" r="12"  fill="white" stroke="${outLineColor}" stroke-width="2" />`
    );

    const textX = i++ >= 9 ? chair.x - 8 : chair.x - 3; // lazy justify text center
    const textY = chair.y + 4;
    chairs.push(
      `<text x="${textX}" y="${textY}" style="color: ${outLineColor}; font: italic 13px sans-serif;">
      ${i + 1}
      </text>`
    );
  }

  return btoa(`<svg width="${data.size}" height="${
    data.size
  }" version="1.1" xmlns="http://www.w3.org/2000/svg">
  <circle cx="${data.center}" cy="${data.center}" r="${
    data.radius
  }"  fill="${fill}" stroke="${outLineColor}" stroke-width="2" />
  ${chairs.join("\n")}
  </svg>
  `);
}

export function generateRectangleTableSvg(
  selected: boolean,
  entity: RectangleTable
) {
  return btoa(
    '<svg width="120" height="120" version="1.1" xmlns="http://www.w3.org/2000/svg">' +
      '<circle cx="60" cy="60" r="60"/>' +
      "</svg>"
  );
}

export function gridSvg() {
  return btoa(`<svg width="50" height="50" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M20 9.33333V6C20 4.89543 19.1046 4 18 4H14.6667M20 9.33333H14.6667M20 9.33333V14.6667M4 9.33333V6C4 4.89543 4.89543 4 6 4H9.33333M4 9.33333H9.33333M4 9.33333V14.6667M14.6667 9.33333H9.33333M14.6667 9.33333V4M14.6667 9.33333V14.6667M9.33333 9.33333V4M9.33333 9.33333V14.6667M20 14.6667V18C20 19.1046 19.1046 20 18 20H14.6667M20 14.6667H14.6667M4 14.6667V18C4 19.1046 4.89543 20 6 20H9.33333M4 14.6667H9.33333M14.6667 14.6667H9.33333M14.6667 14.6667V20M9.33333 14.6667V20M9.33333 4H14.6667M9.33333 20H14.6667" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  `);
}
